import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { PdfExportComponent } from './components/pdf-export/pdf-export.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
@NgModule({
  declarations: [AppComponent,PdfExportComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule, FormsModule, SwiperModule,  NgxQRCodeModule],
  providers: [ReactiveFormsModule, BarcodeScanner,PDFGenerator,PdfExportComponent,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
