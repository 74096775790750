import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as qrcode from 'qrcode';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
@Component({
  selector: 'app-pdf-export',
  templateUrl: './pdf-export.component.html',
  styleUrls: ['./pdf-export.component.scss'],
})
export class PdfExportComponent implements OnInit {
  @Input() order;
  @Input() listado;
  content: string;
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;
  base64Image: string;
  base64Image_olas: string;
  validar_link: string;
  qrCodeBase64: string = '';
  valor = 'https://vitac.epacartagena.gov.co/';
  validar = 'https://vitac.epacartagena.gov.co/contratistas/';
  planta= 'https://vitac.epacartagena.gov.co/planta/';
  constructor(private modalContrller: ModalController) {


  }
  closeModal() {
    this.modalContrller.dismiss();
  }
  async get_olas64() {
    try {
      const imagePath = '../assets/img/olas-carnet-epa.png'; // Relative path to the image

      const imageBlob = fetch(imagePath).then(response => response.blob());

      const reader = new FileReader();

      reader.onload = () => {
        this.base64Image_olas = "" + reader.result as string;
        // console.log('Base64 Encoded Image:', this.base64Image_olas);

      };

      reader.readAsDataURL(await imageBlob);

    } catch (error) {
      console.error('Error encoding image:', error);
    }
  }
  async get_logo64() {
    try {
      const imagePath = '../assets/img/logo.png'; // Relative path to the image

      const imageBlob = fetch(imagePath).then(response => response.blob());

      const reader1 = new FileReader();

      reader1.onload = () => {
        this.base64Image = "" + reader1.result as string;

        // console.log('Base64 Encoded Image:', this.base64Image);

      };

      reader1.readAsDataURL(await imageBlob);

    } catch (error) {
      console.error('Error encoding image:', error);
    }
  }

  async get_linkqr4() {

    if (this.listado[0].field_denominacion_del_cargo.length > 1) {
      this.validar_link = 'https://vitac.epacartagena.gov.co/planta/' + this.listado[0].field_identificacion;
    } else {
      this.validar_link = 'https://vitac.epacartagena.gov.co/contratistas/' + this.listado[0].field_identificacion;

    }

    qrcode.toDataURL(this.validar_link, (error, dataUrl) => {
      if (error) {
        console.error('Error generating QR code:', error);
      } else {
        this.qrCodeBase64 =`<img src="`+dataUrl+`"/>`;

      }
    });



  }

  async downloadInvoice() {




    //this.content = document.getElementById('PrintInvoice').innerHTML;

    var $img_insert = this.listado[0].user_picture_1;

    if (this.listado[0].field_denominacion_del_cargo == "") {

      var   $cargo = "CONTRATISTA";

    } else {
      $cargo = this.listado[0].field_denominacion_del_cargo;
}




console.log($img_insert , 'img ok');
    var html = htmlToPdfmake(`


    <table   data-pdfmake="{&quot;widths&quot;:[100,&quot;*&quot;,&quot;auto&quot;],&quot;heights&quot;:40}">
      <tr style="text-align:center; border:0px;">
      <td style="text-align:center;" colspan="3">
      <img style="width:400px; height:auto;"  src="`+this.base64Image+`"/>
      </td>
      </tr>
      <tr  style="text-align:center; border:0px;">
        <td>&nbsp;</td>
        <td style="text-align:center; border:0px;" >


        `+ $img_insert +`

        </td>
        <td style="text-align:right">&nbsp;</td>
      </tr>
      <tr style="text-align:center; border:0px;">
      <td colspan="3" >
      <h2 class="title">
      `+ this.listado[0].title
      +`
      </h2>
      <h2 class="subtitle" >
      `+ this.listado[0].field_identificacion
      +`
      </h2>
      <h2 class="title">
      `+   $cargo
      +`
      </h2>
      <img style="width:600px; height:auto;"  src="`+ this.base64Image_olas +`"/>
      </td>
    </tr>
    </table>
<p class="pdf-pagebreak-before" style="display:none" >&nbsp;</p>
    <table  data-pdfmake="{&quot;widths&quot;:[100,&quot;*&quot;,&quot;auto&quot;],&quot;heights&quot;:40}">
    <tr style="text-align:center; border:0px;">
    <td style="text-align:center;" colspan="3">
    <img style="width:400px; height:auto;"  src="`+this.base64Image+`"/>
    </td>
    </tr>
    <tr  style="text-align:center; border:0px;">
      <td>&nbsp;</td>
      <td >
      <p class="tc">
Este carnet es personal e intransferible,
favor portarlo en un lugar visible y
presentarlo cuando le sea solicitado. En
caso de pérdida o robo, usted será
responsable del uso indebido que se
haga de este, hasta tanto no sea
denunciado ante las autoridades
competentes.
</p>
      `+ this.qrCodeBase64 +`

      <p class="tc">
      En caso de encontrar este carnet favor
      enviarlo a la oficina de Manga, 4ta Avenida calle  28 # 27-05 Edificio Seaport Centro Empresarial, Cartagena - Bolívar.


      </p>

      </td>
      <td style="text-align:right">&nbsp;</td>
    </tr>
    <tr style="text-align:center; border:0px;">
    <td colspan="3">


    <img style="width:600px; height:auto; filter: grayscale(100%);"  src="`+ this.base64Image_olas +`"/>


    </td>
  </tr>

  </table>


  `, {window:window, tableAutoSize:true});

  /*var html = htmlToPdfMake(``, {window:window});
  console.log(JSON.stringify(html))*/
  pdfMake.fonts = {
    Roboto: {
      normal: 'Roboto-Regular.ttf',
      bold: 'Roboto-Medium.ttf',
      italics: 'Roboto-Italic.ttf',
      bolditalics: 'Roboto-MediumItalic.ttf'
    }
  };
    var documentDefinition = {
      pageSize:'LETTER',

    content: [
      html
      ],
      defaultStyle: {
        font: 'Roboto'
      },

    pageBreakBefore: function(currentNode) {
      // we add a page break before elements with the classname "pdf-pagebreak-before"
      return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      },

      styles: {
        tc: {
          fontSize: 10,
          alignment: 'justify',

        },
        title: {
          lineHeight:1.5,
          color: '#83B551',

        },
        subtitle: {
          lineHeight:0.1,
          color: '#51A8B1',

        },
      red:{
        color:'red'
      },
      blue:{
        color:'blue'
      },
      bold:{
        bold:true
      },
      'html-h6':{
        color:'purple'
      },
      'html-strong':{
        color:'purple'
      },
      'a':{
        bold:true
      },
      'b':{
        italics: true
      },
      'c':{
        color:'red',
        italics: false
      },
      'with-spaces':{
        preserveLeadingSpaces: true
        },


    }
  };


    pdfMake.createPdf(documentDefinition).open();

    console.log('Printing Invoice Page2');


  }
  ngOnInit() {
    this.get_logo64();
    this.get_olas64();
    this.get_linkqr4();


    console.log('Invoice Page2', this.order);
    console.log('Datos del Carnet', this.listado);

  }


}
